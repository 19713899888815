// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const ChargesExport = (records) => {
    const dataForExport = records.map(record => {
        const {
            id,	uuid,	status,	createdAt,	modifiedAt	,displayId, organisationUuid, statementUrlPdf,	statementUrlCsv	, countryUuid, draftInvoiceUuid,	skuUuid, chargeType, feeType,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'organisationId',	'organisationName',	'netsuiteDocNumber',	'dueDate',	'subTotal.value',	'netTotal.value',	'totalDiscount.value', 	'description',	'volume', 'unitAmount',	'quantity', 'total', 'invoiceDate'

        ], // order fields in the export
        rename:[
            'Id',	'Organisation',	'Netsuite Doc Number',	'Invoice Date',	'Due Date',	'SubTotal',	'Net Total',	'Discount', 	'Description',	'Volume', 'Unit Amount',	'Quantity', 'Total', 'Created Date'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'Invoices_'+ today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default ChargesExport;
