import React, {Fragment, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField, Filter, ReferenceInput, AutocompleteInput, SelectInput, useLoading, DateInput, TextInput,
    SelectArrayInput, NumberInput,
    useListContext, useList, useAuthenticated
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import EmptyRecordIcon from '@material-ui/icons/Inbox';

import {
    applyCurrentFilter, CollectionTime,
    CustomPagination, DispatchStatuses,
    formatFeeDisplay, getMarketColorCode, LocationRoleTypes, OpenDays, OrderStatuses, OrderTypes,
    ProcessStatusDisplay, setCurrentFilters
} from "../../utilities/Common";

import {Container, LinearProgress, Box, CircularProgress, Button} from "@material-ui/core";
import CollectionsExport from "./CollectionsExport";
import CollectionsListActions from "./CollectionsListActions";
import BackIcon from "@material-ui/icons/ArrowBack";
import Link from "@material-ui/core/Link";
import {urlencoded} from "express";

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }
    },
    collectionIdFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        }
    },
    organisationFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        width: '20em'
    },
    createdByFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        width: '15em'
    },
    collectTimeFilter: {
        width: '12em'
    },
    postcodeFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        width: '8em'
    },
    openDays: {
        minWidth: '9em'
    },
    isAssignedFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        width: '15em'
    },
}));

const useStyles = makeStyles(styles);
const collectionFilters = ['id', 'createdBy', 'createdAt_from_date', 'createdAt_to_date', 'originOrganisationUuid', 'approxQuantity', 'openDays', 'collectFromTime', 'collectToTime',
    'status', 'collectorOrganisationUuid', 'preferredCollectorUuid', 'fromState', 'fromPostcode', 'fromLocality'];

const FullName = (record) => {
    return `${record.firstName} ${record.lastName}`
}

const PreferredPickup = ({record}) => {
    return(
        <React.Fragment>
            {
                record.collectFromTime ?
                    <span>{ `${record.collectFromTimeText} - ${record.collectToTimeText} ${record.openDaysText}` }</span>
                    :
                    null
            }
        </React.Fragment>
    )
}

const AssignedCollector = ({record}) => {
    return(
        <React.Fragment>
            { record.collectorOrganisationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.collectorOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.collectorOrganisation}</span>
                </Link>
                : null } <br/>
            { record.collectorLocationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.collectorOrganisationId}/locations/view/${record.collectorLocationId}`}>
                    <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.collectorLocation}</span> <br/>
                </Link>
                : null }
        </React.Fragment>
    )
}
const PreferredCollector = ({record}) => {
    return(
        <React.Fragment>
            { record.preferredCollectorId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.preferredCollectorId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.preferredCollectorName}</span>
                </Link>
                : null }
        </React.Fragment>
    )
}

const ListFilters = props => {
    const classes = useStyles();

    // setCurrentFilters(props, collectionFilters, 'collections');

    return (
        <Filter {...props}>
            <ReferenceInput source="id" reference="collections" label={'ID'}
                            filter={{market: localStorage.getItem('mId')}}
                            className={classes.collectionIdFilter}
                            alwaysOn resettable allowEmpty emptyValue={null}>
                <AutocompleteInput optionText="displayId" />
            </ReferenceInput>
            <ReferenceInput source="createdBy" reference="collections/users" label={'Created By'}
                            filter={{createdBy: '', market: localStorage.getItem('mId')}}
                            resettable allowEmpty emptyValue={null} perPage={1000} className={classes.createdByFilter} >
                <AutocompleteInput optionText={'createdByFullName'} />
            </ReferenceInput>
            <DateInput label={'Created On - From'} source={'createdAt_from_date'} alwaysOn resettable />
            <DateInput label={'Created On - To'} source={'createdAt_to_date'} alwaysOn resettable />

            <ReferenceInput source="originOrganisationUuid" reference="organisations"
                            filter={{market: localStorage.getItem('mId')}}
                            label={'Requested From'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput source={'approxQuantityFrom'} label={'No. of Kegs - From'} />
            <NumberInput source={'approxQuantityTo'} label={'No. of Kegs - To'} />
            <SelectArrayInput source="openDays" label={'Open Days'} choices={OpenDays} className={classes.openDays} resettable alwaysOn />
            <SelectInput source="collectFromTime" label={'Collection Time From'} choices={CollectionTime} alwaysOn className={classes.collectTimeFilter}  />
            <SelectInput source="collectToTime" label={'Collection Time To'} choices={CollectionTime} alwaysOn />
            <SelectInput label={'Status'} source="status" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Complete' },
                { id: 0, name: 'Incomplete' },
            ]} allowEmpty={false} alwaysOn />
            <SelectInput label={'Assigned/Unassigned Collector'} source="isAssigned" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Assigned only' },
                { id: 0, name: 'Unassigned only' },
            ]} allowEmpty={false} alwaysOn className={classes.isAssignedFilter} />
            
            <ReferenceInput source="preferredCollectorUuid" reference="organisations"
                            filter={{market: localStorage.getItem('mId')}}
                            label={'Preferred Collector'}
                            className={classes.organisationFilter} alwaysOn resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput source="collectorOrganisationUuid" reference="organisations"
                            filter={{market: localStorage.getItem('mId')}}
                            label={'Assigned Collector'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput source="fromState" reference="collections/fromlocation"
                            filter={{fromState: '', market: localStorage.getItem('mId')}}
                            label={'State'} resettable>
                <AutocompleteInput optionText="value" />
            </ReferenceInput>
            {/*<ReferenceInput source="fromPostcode" reference="collections/originlocation"*/}
            {/*                filter={{fromPostcode: '', market: localStorage.getItem('mId')}}*/}
            {/*                className={classes.postcodeFilter}*/}
            {/*                label={'Postcode'} alwaysOn resettable >*/}
            {/*    <AutocompleteInput optionText="value" />*/}
            {/*</ReferenceInput>*/}
            <TextInput source="fromPostcode" className={classes.postcodeFilter} label={'Postcode'} alwaysOn resettable />
            <ReferenceInput source="fromLocality" reference="collections/originlocation"
                            filter={{fromLocality: '', market: localStorage.getItem('mId')}}
                            label={'Locality'} resettable>
                <AutocompleteInput optionText="value" />
            </ReferenceInput>
        </Filter>
    )
};

const CollectionsList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = { setErrorMessage: setErrorMessage,
                         setSuccessMessage: setSuccessMessage }

    let defaultFilterValues = {market: marketId, tz: Intl.DateTimeFormat().resolvedOptions().timeZone};
    // defaultFilterValues = applyCurrentFilter(defaultFilterValues, collectionFilters, 'collections');

    return (
        <Fragment>
            <Container maxWidth={false}>
                { loading && <LinearProgress /> }
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', padding:'5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'}}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}} /> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      actions={<CollectionsListActions state={state} />}
                      filterDefaultValues={defaultFilterValues}
                      filters={ <ListFilters /> }
                      sort={{ field: 'id', order: 'desc' }}
                      perPage={10}
                      bulkActionButtons={false}
                      exporter={CollectionsExport}
                      pagination={<CustomPagination />}
                >
                    <Datagrid>

                        <FunctionField source={'id'} render={record => <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/collections/${record.id}/details`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.id}</span>
                        </Link> } label={'ID'} />
                        <NumberField source="approxQuantity" label={'No. of Kegs'} />
                        <DateField source="createdAt" label={'Created On'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                                   options={{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }} />
                        <FunctionField source={'createdBy'} render={record =>
                            <span>{ `${record.createdByUserFirstName} ${record.createdByUserLastName}` }</span>
                        } label={'Created By'} sortable={false} />

                        <FunctionField source={'originLocation'} render={record =>
                            <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.originOrganisationId}/locations/view/${record.originLocationId}`}>
                                <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.originLocation}</span>
                            </Link>
                        } label={'From Venue'} sortable={false} />

                        <TextField source={'fromState'} label={'Area'} />
                        <TextField source={'fromPostcode'} label={'Postcode'} />
                        <TextField source={'fromLocality'} label={'Locality'} />

                        <FunctionField source={'preferredPickup'} render={record =>
                            <PreferredPickup record={record} />
                        } label={'Preferred Pickup'} sortable={false} />

                        <FunctionField source={'preferredCollector'} render={record =>
                            <PreferredCollector record={record} />
                        } label={'Preferred Collector'} sortable={false} />

                        <FunctionField source={'assignedCollector'} render={record =>
                            <AssignedCollector record={record} />
                        } label={'Assigned Collector'} sortable={false} />

                        <TextField source={'statusText'} label={'Status'} sortable={false} />
                        <NumberField source={'collectedCount'} label={'Collected Count'} />

                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

}

export default CollectionsList;

