import * as React from 'react';
import {useCallback, FunctionComponent, useState} from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
    fetchRelatedRecords,
    useDataProvider,
    useNotify,
    useListContext,
    SortPayload,
    Exporter,
    FilterPayload,
    useResourceContext,
} from 'ra-core';
import Button, { ButtonProps } from '../../components/button/Button';
import {downloadProgress} from "../../utilities/Common";

const ChargesExportButton: FunctionComponent<ExportButtonProps> = props => {
    const {
        maxResults = 200000,
        onClick,
        label = 'ra.action.export',
        icon = defaultIcon,
        exporter: customExporter,
        sort, // deprecated, to be removed in v4
        ...rest
    } = props;
    const {
        filterValues,
        currentSort,
        exporter: exporterFromContext,
        total,
    } = useListContext(props);
    const resource = useResourceContext(props);
    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isExporting, setIsExporting] = useState(false);
    let records = [];
    let currentPage = 1;
    let perPage = 10000;

    const handleClick = useCallback(
        event => {
            dataProvider
                .getList(resource, {
                    sort: currentSort || sort,
                    filter: filterValues,
                    pagination: { page: 1, perPage: maxResults },
                })
                .then(
                    ({ data }) =>
                        exporter &&
                        exporter(
                            data,
                            fetchRelatedRecords(dataProvider),
                            dataProvider,
                            resource
                        )
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', 'warning');
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            currentSort,
            dataProvider,
            exporter,
            filterValues,
            maxResults,
            notify,
            onClick,
            resource,
            sort,
        ]
    );

    const handleExporter = (event) => {
        setIsExporting(true);
        if(isExporting) {
            return false;
        }
        dataProvider
            .getList(resource, {
                sort: currentSort || sort,
                filter: filterValues,
                pagination: { page: currentPage, perPage: perPage },
            })
            .then(
                ({ data }) => {
                    records = records.concat(data);
                    let paginate = currentPage;
                    let notifyMessage = '';
                    if(currentPage <= (total/perPage) ) {
                        paginate = paginate + 1;
                        currentPage = paginate;
                        notifyMessage = 'Please wait... Generating report progress... [ ' + downloadProgress(currentPage, total, perPage) + '% ]';
                        handleExporter(event);
                    } else {
                        exporter &&
                        exporter(
                            records,
                            fetchRelatedRecords(dataProvider),
                            dataProvider,
                            resource
                        )
                        notifyMessage = 'Generate report complete! Downloading file... ';
                        setIsExporting(false);
                    }
                    notify(notifyMessage, 'info');
                }
            )
            .catch(error => {
                console.error(error);
                notify('ra.notification.http_error', 'warning');
            }).finally(()=>{
            setIsExporting(false);
        });
    }

    return (
        <Button
            onClick={handleExporter}
            label={label}
            disabled={total === 0}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
                               basePath,
                               filterValues,
                               resource,
                               ...rest
                           }: Omit<ExportButtonProps, 'sort' | 'maxResults' | 'label' | 'exporter'>) =>
    rest;

interface Props {
    basePath?: string;
    exporter?: Exporter;
    filterValues?: FilterPayload;
    icon?: JSX.Element;
    label?: string;
    maxResults?: number;
    onClick?: (e: Event) => void;
    resource?: string;
    sort?: SortPayload;
}

export type ExportButtonProps = Props & ButtonProps;

ChargesExportButton.propTypes = {
    basePath: PropTypes.string,
    exporter: PropTypes.func,
    filterValues: PropTypes.object,
    label: PropTypes.string,
    maxResults: PropTypes.number,
    resource: PropTypes.string,
    sort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    icon: PropTypes.element,
};

export default ChargesExportButton;
