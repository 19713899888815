import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    Filter, ReferenceInput, AutocompleteInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {
    applyCurrentFilter,
    formatFeeDisplay,
    setCurrentFilters,
    CustomPagination
} from "../../../utilities/Common";
import {Box, Container, Typography} from "@material-ui/core";
import ArrowDownIcon from "@material-ui/icons/ArrowDownward";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import InvoiceItemsExport from "./InvoiceItemsExport";

export const styles = (theme => ({
    organisationFilter: {
        width: '25em'
    },
}));

const useStyles = makeStyles(styles);

const ListFilters = ({classes, ...props}) => {
    setCurrentFilters(props,
        ['organisationUuid']);

    return(
        <Filter {...props}>
            <ReferenceInput source="organisationUuid" reference="organisations"
                            filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                            sort={{name: 'asc'}}
                            resource="name" label={'Organisation'} className={classes.organisationFilter} alwaysOn allowEmpty={false} resettable>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

        </Filter>
    )
}

const StatementFiles = ({record}) => {

    return (
        <React.Fragment>
            <Box display={'flex'}>
                <Link href={process.env.REACT_APP_TRACKS_URL + record.statementUrlCsv} target="_blank" rel="noreferrer" style={{paddingRight: '10px'}}>
                    <Typography><ArrowDownIcon  /> </Typography>
                </Link>
                &nbsp;
                <Link href={process.env.REACT_APP_TRACKS_URL + record.statementUrlPdf} target="_blank" rel="noreferrer" >
                    <Typography><PDFIcon /></Typography>
                </Link>
            </Box>
        </React.Fragment>
    )
}

const DraftInvoiceItemsList = ({processData, ...props}) => {
    const processUuid = processData.childrenProcess.DraftInvoice.uuid;
    const classes = useStyles();
    let defaultFilterValues = { processUuid: processUuid };
    defaultFilterValues = applyCurrentFilter(defaultFilterValues, ['organisationUuid']);

    return (
        <Fragment>
            <Container style={{width: 'auto', overflowX: 'scroll', maxWidth: '100vw', padding: '8px'}}>
                {/* Content Start */}
                <List {...props} hasCreate={false}
                      filter={{processUuid: processUuid}}
                      filterDefaultValues={defaultFilterValues}
                      filters={<ListFilters classes={classes} />}  perPage={10} bulkActionButtons={false}
                      exporter={InvoiceItemsExport}
                      pagination={<CustomPagination />}
                      empty={<Box textAlign="center" m={1}>
                          <Typography variant="h4" paragraph>
                              No Monthly Invoices yet.
                          </Typography>
                      </Box>}
                >
                    <Datagrid>

                        { processData.childrenProcess.InvoiceStatement && processData.childrenProcess.InvoiceStatement.processStatus == 3 &&
                         <FunctionField render={record => (<StatementFiles record={record}/>)} label={''}/>
                        }

                        <TextField source="organisationId" label={'Id'} sortable={false}/>
                        <TextField source="organisationName" label={'Organisation'} sortable={false}/>
                        <TextField source="description" label={'Fee Description'} sortable={false}/>
                        <TextField source="volume" label={'Volume'} sortable={false}/>
                        <NumberField source="quantity" label={'Quantity'} sortable={false}/>
                        <FunctionField render={record => ( formatFeeDisplay(record.unitAmount)) } label={'Unit Amount'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.total)) } label={'Total'} />
                        <TextField source="netsuiteDocNumber" label={'Netsuite Doc Number'} sortable={false}/>
                        {/*<TextField source="invoiceDate" label={'Invoice Date'} sortable={false}/>*/}
                        <TextField source="dueDate" label={'Due Date'} sortable={false}/>
                        <TextField source="subTotal.value" label={'Sub Total'} sortable={false}/>
                        <TextField source="totalDiscount.value" label={'Total Discount'} sortable={false}/>
                        <TextField source="netTotal.value" label={'Net Total'} sortable={false}/>

                    </Datagrid>
                </List>
                {/* Content End */}
            </Container>
        </Fragment>
    );

}

export default DraftInvoiceItemsList;

