// @ts-nocheck
import * as React from 'react';
import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import {
    Box, Button,
    Container, Grid, Paper,
    Typography,
} from '@material-ui/core';

import { useParams } from 'react-router-dom';
import Divider from "@material-ui/core/Divider";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {DateInput, TextInput, SelectInput, SimpleForm, Toolbar} from "react-admin";
import {OpenDays} from "../../../utilities/Common";
import {makeStyles} from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Link from "@material-ui/core/Link";
import {useEffect, useState} from "react";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import {useFormState} from "react-final-form";


const useStyles = makeStyles(theme => ({
    dateFields: {
        minWidth: '230px',
        margin: '10px'
    },
    textFields: {
        minWidth: '220px',
        margin: '10px'
    },
    selectFields: {
        minWidth: '220px',
        margin: '10px'
    },
    selectBooleanFields: {
        minWidth: '170px',
        margin: '10px'
    },
}));


const RecurringCollection = props => {
    const { organisationUuid, recurringCollectionRequestId, mode } = useParams();
    let enableEditing = false;
    let modeDisplay = 'View';
    const classes = useStyles();
    let baseUrl = `${process.env.REACT_APP_KEGNOVA_API_URL}/recurring-collection/${recurringCollectionRequestId}`;
    const [recurringCollectionUrl, setRecurringCollectionUrl] = useState(baseUrl);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState([]);
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                if (mode === 'add') {
                    return;
                }
                const result = await axios.get(recurringCollectionUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });

                setData(result.data);
                setLoading(false);
            } catch (error) {
                // console.log(error)
                setErrorMessage('Retrieving Recurring Collections failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [recurringCollectionUrl]);


    const handleSubmit = async (e, formState) => {
        e.preventDefault();
        let form = formState.values;
        let payload =  data;


        payload = { ...payload,
            startDate: form['startDate'],
            recurringCollectionStartAt: form['recurringCollectionStartAt'],
            recurringCollectionEndAt: form['recurringCollectionEndAt'],
            type: form['type'],
            dayOfWeek: form['dayOfWeek'],
            separationCount: form['separationCount'],
            templateCollectionRequestId: form['templateCollectionRequestId'],
            combineCollections: form['combineCollections']
        }

        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/recurring-collection`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };

            if(mode === 'edit'){
                const result = await axios.put(url, payload, options);
            } else if (mode === 'add') {
                const result = await axios.post(url, payload, options);
            }

            setSuccess(true);

        } catch (error) {
            setErrorMessage('Error updating package' + (error.response.length > 0) ? error.response.data.message : '');
        }

    }

    switch (mode) {
        case 'add':
            enableEditing = true;
            modeDisplay = 'Add a';
            break;
        case 'edit':
            enableEditing = true;
            modeDisplay = 'Edit';
            break;
        case 'delete':
            modeDisplay = 'Delete';
            enableEditing = true;
            break;
        default:
            modeDisplay = 'View';
            enableEditing = true;
            break;
    }

    if ( !(uuidValidate(organisationUuid) && uuidVersion(organisationUuid) === 4) ) {
        enableEditing = false;
    }

    if (!enableEditing) {
        return (
            <Container>
                <Box m={5}>
                    <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Error </AlertTitle>
                        Invalid page option. Please check the url/page options or go back to the previous page and try again. If issue persist, contact Teach Team.
                    </Alert>
                </Box>
            </Container>
        )
    }

    const ActionToolbar = ({classes, ...props}) => {
        const formState = useFormState();
        return(
            <Toolbar {...props} >
                <Box>
                    <Button variant={'contained'} onClick={(e)=>handleSubmit(e, formState)}
                            disabled={isSubmitting}><SaveIcon />&nbsp; Save Package</Button>
                    <Link href={`/#/organisations/settings/${organisationUuid}`} color={'inherit'}>
                        <Button variant={'contained'}> Cancel </Button>
                    </Link>
                </Box>
            </Toolbar>
        )
    };

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'} >Setup Recurring Collection </Typography>
            </Box>
            <Box m={1}>
                <Divider />
            </Box>
            <Box m={2}>
                <Typography variant={'body1'} style={{fontWeight: 'bold'}}>{ 'Organisation' } <DoubleArrowIcon  style={{fontSize: '12px', paddingLeft: '5px', paddingRight: '5px', margin: 0}} /> { 'YOUNG HENRY\'S BREWING CO' }</Typography>
            </Box>
            <Box m={2}>
            </Box>
            <Box m={2}>
                <Typography variant={'h6'} >{modeDisplay} Recurring Collection {recurringCollectionRequestId}</Typography>
            </Box>
            { success &&
            <Box m={2}>
                <React.Fragment>
                    <Box m={1}></Box>
                    <Box display={'inline'}>
                        <Alert severity="success">
                            <AlertTitle variant={'h6'}> Success </AlertTitle>
                            {modeDisplay} Recurring Collection Request Successful!
                        </Alert>
                    </Box>
                </React.Fragment>
            </Box>
            }
            { errorMessage &&
            <Box m={2}>
                <React.Fragment>
                    <Box display={'inline'}>
                        <Alert severity="error">
                            <AlertTitle variant={'h6'}> Recurring Collections Error </AlertTitle>
                            {modeDisplay} Recurring Collection Request Failed!
                            <Box m={1}>
                                {errorMessage}
                            </Box>
                        </Alert>
                    </Box>
                </React.Fragment>
            </Box>
            }
            { mode === 'delete' ?
                <React.Fragment>
                    <Alert severity="info" style={{display: 'flex', width: '60%'}}>
                        <AlertTitle variant={'h6'}> Delete Recurring Collection {recurringCollectionRequestId} </AlertTitle>
                            You're about to delete a Recurring Collection. Do you want to proceed?
                    </Alert>
                    <Box m={3}>
                        <Button variant={'contained'} onClick={()=>{}} style={{marginRight: '5px'}}>Yes</Button>
                        <Link href={`/#/organisations/settings/${organisationUuid}`} color={'inherit'}>
                            <Button variant={'contained'}> No </Button>
                        </Link>
                    </Box>
                </React.Fragment>
                :
                <Box m={2}>

                    { data &&
                    <SimpleForm toolbar={<ActionToolbar />}>
                        <Grid container spacing={2} style={{width: '100%'}}>
                            <Grid item xs={5}>
                                <DateInput source="recurringCollectionStartAt" label={'Start Date'} defaultValue={data.recurringCollectionStartAt} className={classes.dateFields}/>
                                <DateInput source="recurringCollectionEndAt" label={'End Date'} defaultValue={data.recurringCollectionEndAt} className={classes.dateFields} />
                                <SelectInput name="type" label="Recurring Type" choices={[
                                    { id: 1, name: 'Weekly' },
                                    { id: 2, name: 'Monthly' }
                                ]} className={classes.selectFields} defaultValue={data.type} />

                                <SelectInput name="dayOfWeek" label="Day of The Week"  choices={OpenDays} className={classes.selectFields} defaultValue={data.dayOfWeek} />
                                <TextInput source="separationCount" label={'Repeat Every'} className={classes.textFields} defaultValue={data.separationCount} />
                                <TextInput source="templateCollectionRequestId" label={'Collection Request Template'}
                                           className={classes.textFields} defaultValue={data.templateCollectionRequestId} />
                                {/*<SelectInput name="dayOfTheWeek" label="Day of The Week"  choices={OpenDays} className={classes.selectFields} />*/}
                                {/*<SelectInput name="templateCollectionRequestId" label="Collection Request Template"*/}
                                {/*             choices={[ {id: 268097, name: "268097"},{id: 268099, name: "268099"},]}*/}
                                {/*             className={classes.selectFields} defaultValue={data.templateCollectionRequestId}*/}
                                {/*/>*/}
                                <SelectInput name="combineCollections" label="Combine Collection?" choices={[
                                    { id: true, name: 'Yes' },
                                    { id: false, name: 'No' }
                                ]} className={classes.selectBooleanFields}  defaultValue={data.combineCollections}  />
                            </Grid>

                        </Grid>

                    </SimpleForm>
                    }

                </Box>

            }

        </Container>
    );
};

export default RecurringCollection;
