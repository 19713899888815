import * as React from 'react';
import { Route } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Configuration from './configuration/Configuration';
import OrderFeeOverride from './pages/billing/OrderFeeOverride';
import Pricing from "./pages/billing/Pricing";
import OrganisationPackages from "./pages/billing/OrganisationPackages";
import CountryPackages from "./pages/billing/CountryPackages";

import LocationPackages from "./pages/billing/LocationPackages";
// import DraftCharges from "./pages/billing/DraftCharges";
import GenerateCharges from "./pages/billing/GenerateCharges";
import ProcessesList from "./pages/billing/ProcessesList";
import GenerateInvoices from "./pages/billing/GenerateInvoices";
import DraftProcessesList from "./pages/billing/DraftProcessesList";
import GenerateDraftCharges from "./pages/billing/GenerateDraftCharges";
import PackageEdit from "./pages/billing/PackageEdit";
import Orders from "./pages/kegs/Orders";
import Dispatches from "./pages/kegs/Dispatches";
import Collections from "./pages/kegs/Collections";
import OrganisationSettings from "./pages/settings/OrganisationSettings";
import RecurringCollection from "./pages/settings/recurringcollections/RecurringCollection";

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/billing/generate/charges/draft" render={() => <GenerateDraftCharges />} />,
    // <Route exact path="/billing/generate/charges" render={() => <GenerateCharges />} />,
    <Route exact path="/billing/generate/invoices" render={() => <GenerateInvoices />} />,
    <Route exact path="/billing/packages/pricing" render={() => <Pricing />} />,
    <Route exact path="/billing/packages/locations" render={() => <LocationPackages />} />,

    <Route exact path="/billing/packages/countries" render={() => <CountryPackages />} />,
    <Route exact path="/billing/packages/organisations" render={() => <OrganisationPackages />} />,

    <Route
        path="/billing/packages/orders/:action/:uuid"
        children={<OrderFeeOverride />}
    />,

    <Route exact
           path="/billing/packages/:level/:uuid"
           children={ () => <PackageEdit /> }
    />,

    <Route exact
        path="/billing/packages/:level/:uuid/:sku"
        children={ () => <PackageEdit /> }
    />,

    <Route
        path="/billing/processes/draft/:processUuid"
        children={<DraftProcessesList />}
    />,
    <Route
        path="/billing/processes/:processUuid"
        children={<ProcessesList />}
    />,

    <Route exact path="/kegs/orders" render={() => <Orders />} />,
    <Route exact path="/kegs/dispatches" render={() => <Dispatches />} />,
    <Route exact path="/kegs/collections" render={() => <Collections />} />,
    <Route exact path="/organisations/settings/:organisationUuid" render={() => <OrganisationSettings />} />,
    <Route exact path="/organisations/settings/:organisationUuid/recurringcollections/:mode"
           children={ () => <RecurringCollection /> }
    />,
    <Route exact path="/organisations/settings/:organisationUuid/recurringcollections/:mode/:recurringCollectionRequestId"
           children={ () => <RecurringCollection /> }
    />,
    // <Route exact path="/organisations/settings/:organisationUuid/recurringcollections/:collectionRequestId/:mode/:recurringCollectionId"
    //        children={ () => <RecurringCollection /> }
    // />,
];
