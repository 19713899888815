// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const DraftChargesExport = (records) => {

    const dataForExport = records.map(record => {
        const {
            id,	processId,	processUuid, endLocationName,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        // dataForExport.kegSerialNumber = (record.kegs) ? record.kegs.serialNumber : null;

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'chargeId', 'organisationId', 'organisationName',	'applicableDate',	'unitAmount',	'quantity',	'total',	'description',
            'cycleId',	'cycleStartDate',	'cycleEndDate',	'kegSerialNumber',
            'skuName',	'cycleEndLocation',	'cycleEndRole',	'cycleEndRegion',	'orderId'

        ], // order fields in the export
        rename:[
            'Charge Id', 'Organisation Id','Organisation Name',	'Applicable Date',	'Unit Amount',	'Quantity',	'Total',	'Fee Description',
            'Cycle Id',	'Cycle Start Date',	'Cycle End Date', 	'Keg Serial Number',
            'SKU',	'Cycle End Location',	'Cycle End Role',	'Cycle End Region',	'Order Id'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'DraftCharges_'+ today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default DraftChargesExport;
