// @ts-nocheck
import * as React from 'react';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Box, Button, CircularProgress, Container, LinearProgress,
    TextField, Typography, Hidden, FormControlLabel, Checkbox}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Notification, SelectInput, useNotify, useQuery} from 'react-admin';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ErrorAlertBox from "../../components/alerts/ErrorAlertBox";
import EmptyAlertBox from "../../components/alerts/EmptyAlertBox";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Link from "@material-ui/core/Link";
import InputLabel from '@material-ui/core/InputLabel';
import {stopAtRoleDisplay, UnauthorisedAccountingAccess, unauthorisedAccountingAccess} from "../../utilities/Common";

import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    formRoot: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '22ch',
        },
    }
}));

const GetOrderId = ({orderUuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'orders',
        payload: { id: orderUuid }
    });

    if (loading) return <LinearProgress />;
    if (error) return  "Error";
    if (!data) return null;

    return (
        <Typography variant={'h5'}> Order:
            <Link href={process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs/orders/'+data.displayId+'/details'} style={{marginLeft: '6px', color: '#bfbfbf'}} >
                {data.displayId}
            </Link>
        </Typography>
    )
};

const OrderFeeOverride = () => {
    let {action, uuid} = useParams();
    // console.log(action, uuid);
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [noData, setNoData] = useState(false);
    const [success, setSuccess] = useState(false);
    const classes = useStyles();
    const notify = useNotify();
    const [tabValue, setTabValue] = useState(0);
    let viewMode = (action == 'view');
    const [showUpdateFees, setShowUpdateFees]  = useState(false);
    const [packageDetails, setPackageDetails] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);
    const [waivedTimedFee, setWaivedTimedFee] = useState(false);
    const [packageUpdatedBy, setPackageUpdatedBy] = useState(null);
    const [packageUpdatedOn, setPackageUpdatedOn] = useState(null);
    const [checkWaivedTimeFee, setCheckWaivedTimeFee] = useState({});
    const url = `${process.env.REACT_APP_KEGNOVA_API_URL}/packages/details?orderUuid=${uuid}`;
    /** Get Package Details **/
    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const result = await axios.get(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                setPackageDetails(result.data);
                initialisePackageData(result.data.packages);
                if (viewMode) {
                    setShowUpdateFees(true);
                }
            } catch (error) {
                //notify('Error fetching package details', 'warning');
                setHasErrors(true);
                setErrorMessage('Retrieving package for order failed.');
            }
            setLoading(false);
        })();
    }, [url, isUpdated]);

    const payloadRequest = (fees, isComplimentary) => {

        let payload = {
            countryUuid: null,
            organisationUuid: null,
            locationUuid: null,
            orderUuid: uuid,
            skuUuid: null,
            dailyRentalSettings: null,
            startDate: {
                date: startDate,
                timezone_type: 3,
                timezone: localStorage.getItem('timezone')
            },
            endDate: null,
            fees: isComplimentary ? null: fees,
            psaDiscountGroupUuid: null,
            invoicePaymentDays: []
        };

        return payload;
    }

    const handleSubmit = async e => {
        e.preventDefault();

        // notify('billing.updatingpackagedetails', 'info');
        let form = e.target.elements;
        let fees = [];
        let perKegFreightFlag = false;
        let isDepositAppliedFlag = false;

        form.forEach(function(row){

            // console.log(row.name);
            if(row.name && row.value) {
                if(row.name.includes("Freight") && !(row.name.includes("Minimum")) && !row.name.includes("perKegFreight") && !row.name.includes("isDepositApplied") ) {
                    // let minimumFee = row.name.charAt(0).toLowerCase() + row.name.slice(1);
                    fees.push({
                        "name": row.name,
                        "amount": isComplimentary ? 0 : parseFloat(row.value),
                        "minimumFee": isComplimentary ? 0 : parseFloat(row.value) // parseFloat(packageDetails.packageSetGroup[minimumFee+'Settings'].minimumFee)
                    });

                } else {
                    if(!row.name.includes("Minimum") && !row.name.includes("selfDistributionFixedDiscount")){
                        if(row.name.includes("TimedFee")) {
                            fees.push({
                                "name": "TimedFee",
                                "amount": waivedTimedFee ? 0 : packageDetails.packageSetGroup.timedFee,
                                "stopRole": stopAtRoleDisplay(packageDetails.packageSetGroup.timedFeeSettings.stopAtRole),
                                "timedFeeGroupUuid": packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.uuid,
                                "complimentary": waivedTimedFee
                            });

                        } else if (row.name.includes("IssueFee")) {
                            fees.push({
                                "name": isComplimentary ? 0 : row.name,
                                "amount": isComplimentary ? 0 : parseFloat(row.value),
                                "selfDistributionFixedDiscount": parseFloat(form['selfDistributionFixedDiscount'].value)
                            });
                        } else {

                            if (!row.name.includes("perKegFreight") && !row.name.includes("isDepositApplied")) {
                                fees.push({
                                    "name": isComplimentary ? 0 : row.name,
                                    "amount": isComplimentary ? 0 : parseFloat(row.value)
                                });
                            } else {
                                if (row.name.includes("perKegFreight")) {
                                    perKegFreightFlag = row.value == "true";
                                } else if (row.name.includes("isDepositApplied")) {
                                    isDepositAppliedFlag = row.value == "true";
                                }

                            }

                        }
                    }
                }
            }

        });

        let isComplimentary = false;
        let payload = payloadRequest(fees, isComplimentary);

        if (perKegFreightFlag) {
            payload = {...payload, perKegFreight: perKegFreightFlag};
        } else {
            payload = {...payload, perKegFreight: false};
        }

        if (isDepositAppliedFlag) {
            payload = {...payload, isDepositApplied: isDepositAppliedFlag};
        } else {
            payload = {...payload, isDepositApplied: false};
        }

        if (checkWaivedTimeFee) {
            payload = {...payload, waivedTimedFees: checkWaivedTimeFee};
        }

        saveOrderFeeOverride(payload);

    }

    const saveOrderFeeOverride = async (payload, isComplimentary) => {

        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/packages`;
            const options = {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')}
            };
            const result = await axios.post(url, payload, options);
            setIsUpdated(true);
            setLoading(false);
            setHasErrors(false);
            setSuccess(true);
            setShowUpdateFees(true);
            notify('billing.updatepackagesuccessful', 'info');
        } catch (error) {
            notify('billing.updatepackagefailed', 'warning');
            setHasErrors(true);
            setErrorMessage('Error updating package');
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleShowIsComplementary = () => {
        setShowUpdateFees(false);
    };

    const handleIsComplimentaryChange = async (event) => {
        if (isUpdated) {
            setIsUpdated(false);
            setSuccess(false);
            setHasErrors(false)
        }
        let value = (event.currentTarget.value == 1);
        setLoading(true);
        if(value){
            setLoading(false);
            let isComplimentary = true;
            let fees = null;
            const payload = payloadRequest(fees, isComplimentary);
            saveOrderFeeOverride(payload);
        } else {
            setLoading(false);
            setShowUpdateFees(true);
        }
    };

    const handleWaivedTimedFee = (event) => {
        let id = event.target.id;
        let name = event.target.name;
        let value = event.target.checked;
        const { checked } = event.target;
        setCheckWaivedTimeFee((values) => ({
            ...values,
            [id]: checked
        }));
        setWaivedTimedFee(value);
    };


    const validateForm = (values: FormValues) => {
        const errors: FormValues = {};
        // if (!values.username) {
        //     errors.username = translate('ra.validation.required');
        // }
        return errors;
    };

    const initialisePackageData = (packages) => {
        packages.forEach(function(p){
            if(p.orderUuid) {
                setOrderId(p.orderId);
                setStartDate(p.startDate);
            }

            if(!packageUpdatedOn || packageUpdatedOn <= p.modifiedAt ){
                setPackageUpdatedBy(p.updatedByUserName);
                setPackageUpdatedOn(p.modifiedAt);
            }
        });
    }

    // Check if user is allowed to access this accounting page
    if ( unauthorisedAccountingAccess() ) {
        return (
            <UnauthorisedAccountingAccess />
        )
    }

    if (hasErrors) {
        return (
            <ErrorAlertBox errorMessage={errorMessage} />
        )
    }
    if (noData) {
        return (
            <EmptyAlertBox message={'Order Package details not found.'} />
        )
    }

    return (
        <Container >
            <Box p={2} >

                <Box>
                    { loading ? <LinearProgress/>
                        :
                        <GetOrderId orderUuid={uuid}/>
                    }
                </Box>
                <Box m={1}>
                    <Typography variant={'h4'}>
                        { (action == 'edit') ? 'Update' : 'View' } Fees
                    </Typography>
                </Box>
                <Box m={1}>
                    <Divider />
                </Box>
                { success &&
                <Box>
                    <Alert severity="success">
                        <AlertTitle variant={'h6'}> Success </AlertTitle>
                        Order Package updated successfully.
                    </Alert>
                </Box>
                }

                <form onSubmit={handleSubmit} noValidate className={classes.formRoot}>
                    { !loading && !showUpdateFees &&
                    <Box>
                        <Box style={{marginTop: '0', marginBottom: '1em'}}>
                            <Alert severity="info">
                                <AlertTitle>Is this a complimentary order?</AlertTitle>
                                Marking an order "Complimentary" would waive off all charges for the Order
                                and Kegs dispatched for the order including issue fees, freight fees, daily
                                rental fees and timed fees. <br/>
                            </Alert>
                        </Box>
                        <Box m={3}>

                        </Box>
                        <Box>
                            <Box display="inline" style={{marginTop: '1em', marginRight: '0.5em'}}>
                                <Button color="primary" onClick={handleIsComplimentaryChange}
                                        variant="contained" value={1} disabled={loading}> Yes </Button>
                            </Box>
                            <Box display="inline" style={{marginTop: '1em', marginRight: '0.5em'}}>
                                <Button color="primary" onClick={handleIsComplimentaryChange}
                                        variant="contained" value={0} disabled={loading}> No </Button>
                            </Box>
                        </Box>
                    </Box>
                    }

                    {(!loading && showUpdateFees) &&
                    <Box m={1}>

                        <Box m={1}>
                            <Box m={3} />
                            <Box display={'flex'} m={1}>
                                <Box flexGrow={1} >
                                    <Typography variant={'h5'}>Pricing</Typography>
                                </Box>
                                <Box m={1}>
                                    {packageUpdatedBy &&
                                        (<Typography>Last Updated By : {packageUpdatedBy}</Typography>)
                                    }
                                    {packageUpdatedOn &&
                                        (<Typography>Last Updated On : {new Date(packageUpdatedOn).toLocaleString()}</Typography>)
                                    }
                                </Box>
                            </Box>
                            </Box>
                            <Box m={1}>
                                <Divider/>
                            </Box>
                            <Box m={2} />

                        <TextField InputProps={{ readOnly: viewMode }} name="IssueFee" label="Issue fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.issueFee} />
                        <TextField InputProps={{ readOnly: viewMode }} name="selfDistributionFixedDiscount" label="Self Dist. Fixed Discount" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.selfDistributionFixedDiscount}/>

                        <TextField InputProps={{ readOnly: viewMode }} name="DailyRentalFee" label="Daily Rental Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.dailyRentalFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="CleaningFee" label="Cleaning Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.cleaningFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="CleaningSurchargeFee" label="Cleaning Surcharge Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.cleaningSurchargeFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="MissedScanFee" label="Missed Scan Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.missedScanFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="TaproomProducerFee" label="Taproom to Producer Fee"
                                   type="number" InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.taproomProducerFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="TaproomVenueFee" label="Taproom to Venue Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.taproomVenueFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="TaproomDistributorFee" label="Taproom to Distributor Fee"
                                   type="number" InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.taproomDistributorFee}/>

                        <TextField InputProps={{ readOnly: viewMode }} name="FreightCapitalMetroFee" label="Freight Capital Metro Fee"
                                   type="number" InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.freightCapitalMetroFee}/>
                        <Hidden xlDown={true}>
                            <TextField InputProps={{ readOnly: viewMode }} name="FreightCapitalMetroFeeMinimum" type="number"
                                       defaultValue={packageDetails.packageSetGroup.freightCapitalMetroFeeSettings.minimumFee}/>
                        </Hidden>

                        <TextField InputProps={{ readOnly: viewMode }} name="FreightRegionalMetroFee" label="Freight Regional Metro Fee"
                                   type="number" InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.freightRegionalMetroFee}/>
                        <Hidden xlDown={true}>
                            <TextField InputProps={{ readOnly: viewMode }} name="FreightRegionalMetroFeeMinimum" type="number"
                                       defaultValue={packageDetails.packageSetGroup.freightRegionalMetroFeeSettings.minimumFee}/>
                        </Hidden>

                        <TextField InputProps={{ readOnly: viewMode }} name="FreightRegionalFee" label="Freight Regional Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.freightRegionalFee}/>
                        <Hidden xlDown={true}>
                            <TextField InputProps={{ readOnly: viewMode }} name="FreightRegionalFeeMinimum" type="number"
                                       defaultValue={packageDetails.packageSetGroup.freightRegionalFeeSettings.minimumFee}/>
                        </Hidden>

                        <TextField InputProps={{ readOnly: viewMode }} name="FreightSuperRegionalFee" label="Freight Super Regional Fee"
                                   type="number" InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.freightSuperRegionalFee}/>
                        <Hidden xlDown={true}>
                            <TextField InputProps={{ readOnly: viewMode }} name="FreightSuperRegionalFeeMinimum" type="number"
                                       defaultValue={packageDetails.packageSetGroup.freightSuperRegionalFeeSettings.minimumFee}/>
                        </Hidden>

                        <TextField InputProps={{ readOnly: viewMode }} name="FreightPickUpFee" label="Freight Pickup Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.freightPickUpFee}/>
                        <Hidden xlDown={true}>
                            <TextField InputProps={{ readOnly: viewMode }} name="FreightPickUpFeeMinimum" type="number"
                                       value={packageDetails.packageSetGroup.freightPickUpFeeSettings.minimumFee}/>
                        </Hidden>

                        <TextField InputProps={{ readOnly: viewMode }} name="PalletFee" label="Pallet Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.palletFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="ExpressFee" label="Next Day Delivery Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.expressFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="NonMystarFee" label="Non Mystar Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.nonMystarFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="StopAtDistributorFee" label="Stop At Distributor Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.stopAtDistributorFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="InterCountryFee" label="Inter Country Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.interCountryFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="CollectionRegionalFee" label="Regional Collection Fees"
                                   type="number" InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.collectionRegionalFee}/>
                        <TextField InputProps={{ readOnly: viewMode }} name="CollectionSuperRegionalFee" label="Super Regional Collection Fees"
                                   type="number" InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.collectionSuperRegionalFee}/>

                        <TextField InputProps={{ readOnly: viewMode }} name="FixedFee" label="Fixed fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.fixedFee}/>


                        <TextField InputProps={{ readOnly: viewMode }} name="TimedFee" label="Timed Fee" type="number"
                                   InputLabelProps={{shrink: true,}} variant="filled"
                                   defaultValue={packageDetails.packageSetGroup.timedFee} style={{display: 'none'}} />

                        <TextField InputProps={{ readOnly: viewMode }} name="DepositFee" label="Deposit Per Keg Fee" type="number"
                                InputLabelProps={{shrink: true,}} variant="filled"
                                defaultValue={packageDetails.packageSetGroup.depositFee} />

                        <Box display={'flex'}>
                            <Box ml={1}>
                                <InputLabel id="perKegFreightLabel"><Typography variant={'caption'}> Freight Per Keg?</Typography></InputLabel>
                                <Select
                                    labelId="perKegFreightLabel"
                                    defaultValue={packageDetails.packageSetGroup.perKegFreight}
                                    name={'perKegFreight'}
                                    variant={'filled'} style={{width: '200px'}}
                                >
                                    <MenuItem value={false}>No</MenuItem>
                                    <MenuItem value={true}>Yes</MenuItem>
                                </Select>
                            </Box>
                            <Box ml={1}>
                                <InputLabel id="isDepositAppliedLabel"><Typography variant={'caption'}> Deposit Applied?</Typography></InputLabel>
                                <Select
                                    labelId="isDepositAppliedLabel"
                                    defaultValue={packageDetails.packageSetGroup.isDepositApplied}
                                    name={'isDepositApplied'}
                                    variant={'filled'} style={{width: '200px'}}
                                >
                                    <MenuItem value={false}>No</MenuItem>
                                    <MenuItem value={true}>Yes</MenuItem>
                                </Select>
                            </Box>
                        </Box>

                        <Box m={1}><Box m={3} /><Typography variant={'h5'}>Timed Fees</Typography></Box><Box m={1}><Divider/></Box><Box m={2} />

                        <Box>

                            { packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.timedFees.map((row, key) => (
                                <Box display="inline">
                                    <TextField label={`${row.billedAtDay} Days`} type="number"
                                               InputLabelProps={{shrink: true,}} variant="filled"
                                               defaultValue={row.amount} InputProps={{ readOnly: true }} />
                                </Box>
                            ))
                            }

                            {/*{ (action == 'edit') && <FormControlLabel*/}
                            {/*        control={*/}
                            {/*            <Checkbox*/}
                            {/*                checked={waivedTimedFee}*/}
                            {/*                onChange={handleWaivedTimedFee}*/}
                            {/*                name={'waiveTimedFee'}*/}
                            {/*                color="primary"*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*        label="Waive Timed Fees"*/}
                            {/*    />*/}
                            {/*}*/}

                            { (action == 'edit') && <Box m={1}>
                                {packageDetails.packageSetGroup.timedFeeSettings.timedFeeGroup.timedFees.map((row, key) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={`timedFee${row.billedAtDay}`}
                                                checked={checkWaivedTimeFee[row.billedAtDay]}
                                                onChange={handleWaivedTimedFee}
                                                value={row.billedAtDay}
                                                name={`waiveTimedFee`}
                                                color="primary"
                                            />
                                        }
                                        label={`Waive ${row.billedAtDay} Days Timed Fees`}
                                    />
                                ))
                                }
                            </Box>
                            }

                        </Box>
                        <Box m={5}>

                        </Box>
                        {(action == 'edit') &&
                            <Box m={1}>
                                <Box display="inline" style={{marginTop: '1em', marginRight: '0.5em'}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        onClick={handleShowIsComplementary}
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        Go Back
                                    </Button>
                                </Box>
                                <Box display="inline" style={{marginTop: '1em', marginRight: '0.5em'}}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        Update
                                    </Button>
                                </Box>
                            </Box>
                        }

                    </Box>
                    }

                </form>


                <Notification />
            </Box>
        </Container>
    );
};

OrderFeeOverride.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// @ts-ignore
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function formTabProps(index) {
    return {
        id: `order-fee-override-tab-${index}`,
        'aria-controls': `order-fee-override-tabpanel-${index}`,
    };
}

export default OrderFeeOverride;
