// @ts-nocheck
import * as React from 'react';

import {
    Box, Button,
    Container,
    Typography,
}
    from '@material-ui/core';

import OrganisationPackageList from "../../resources/organisations/psg/OrganisationPackageList";
import ScrollToRightSignal from "../../components/gifs/ScrollToRightSignal";
import Link from "@material-ui/core/Link";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Divider from "@material-ui/core/Divider";

const OrganisationPackages = props => {
    // const loading = useLoading();
    let organisationPackageProps = { ...props, basePath: "/organisations/psg", resource: "organisations/psg" }

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'} style={{paddingBottom: '10px', paddingLeft: '10px'}}>Organisation Packages</Typography>
            </Box>
            <Box m={1}>
                <Divider />
            </Box>
            <Box m={1}>
                <ScrollToRightSignal />
            </Box>
            <Box p={2}>

                <OrganisationPackageList {...organisationPackageProps} />

            </Box>
        </Container>
    );
};

export default OrganisationPackages;
