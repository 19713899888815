import {kegLeaseOrderEnabled} from "./index";
import React from "react";

export const dashboardActions = [
    { name: 'Add Order', link: '/kegs/orders/create', isActive: true },
    { name: 'Add KegLease Order', link: '/kegs/orders/create', isActive: kegLeaseOrderEnabled() },
    { name: 'Add Transfer', link: '/kegs/transfers/add', isActive: true },
    { name: 'Add Dispatch', link: '/kegs/dispatches/add', isActive: true },
    { name: 'Add Forecast', link: '/kegs/orders/forecast/', isActive: true },
    { name: 'Add Producer', link: '/producers/add', isActive: true },
    { name: 'Add Logistics Partner', link: '/logistics/add', isActive: true },
    { name: 'Add Venue', link: '/venues/add', isActive: true },
    { name: 'Add Dev Ticket', link: 'https://goo.gl/forms/0DxhYzOny5vluVPa2', isActive: true },
];

export const kegsMenu = [
    { name: 'Overview', isActive: true, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs' },
    { name: 'Cycles', isActive: true, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs/cycles' },
    { name: 'Pallets', isActive: true, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs/pallets' },
    { name: 'Orders', isActive: true, link: '/#/kegs/orders' },
    { name: 'KegLease Orders', isActive: kegLeaseOrderEnabled(), link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs/lease/orders' },
    { name: 'Dispatches', isActive: true, link: '/#/kegs/dispatches' },
    { name: 'Collections', isActive: true, link: '/#/kegs/collections' },
    { name: 'Leads', isActive: true, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs/leads' },
    { name: 'Balance Kegs', isActive: true, link: process.env.REACT_APP_TRACKS_PORTAL_URL+'/#/kegs/transfers' },
];

export const financialMenu = [
    { name: 'Overview', isActive: true, link: '#' },
    { name: 'Monthly Invoices', isActive: true, link: '#' },
    { name: 'Draft Charges', isActive: true, link: '#' },
    { name: 'Pricing', isActive: true, link: '#' },
    { name: 'Forecasting', isActive: true, link: '#' },
    { name: 'Archived', isActive: true, link: '#' },
];

export const adminMenu = [
    { name: 'Overview', isActive: true, link: '#' },
    { name: 'Reports', isActive: true, link: '#' },
    { name: 'Rental Agreements', isActive: true, link: '#' },
    { name: 'Warehouses', isActive: true, link: '#' },
    { name: 'Xero', isActive: true, link: '#' },
    { name: 'Add Kegs', isActive: true, link: '#' },
];

export const dashboardSummaryLabel = {
    collectionRequests: "Collection Requests",
    contactRequests: "Contact Requests",
    cycles: "Cycles",
    dispatches: "Dispatches",
    kegs: "Kegs",
    leaseOrders: "Lease Orders",
    orders: "Orders",
    scans: "Scans"
}